$(document).ready(function () {
  //window.reloadDataTables();
  //$('#fightCarousel').carousel({interval: false});
  $(document).on("mouseleave", "#fightCarousel", function () {
    $(this).carousel("pause");
  });
  // Nav Scroller & Layout
  if ($(window).width() <= 993) {
    $("#nav-container").removeClass("container");
    $("#nav-container").addClass("float-left");
  } else {
    $(window).scroll(function () {
      if ($(document).scrollTop() > 36) {
        $("#main-nav").addClass("shrink");
        $("#main-nav").addClass("fixed-top");
      } else {
        $("#main-nav").removeClass("shrink");
        $("#main-nav").removeClass("fixed-top");
      }
    });
  }

  // Commented out because they error when not present, implemented at page level
  // $('#how-to-watch').DataTable( {
  //  "scrollX":        true,
  //  "paging":         false,
  //  "info":         false,
  //  "searching":    true,
  //  "ordering":       true
  // });

  // $('#fight-history').DataTable( {
  //  "scrollX":        true,
  //  "paging":         false,
  //  "info":         false,
  //  "searching":    false,
  //  "ordering":       false
  // });

  $(document).on("click", '[data-toggle="lightbox"]', function (event) {
    event.preventDefault();
    $(this).ekkoLightbox();
  });

  if ($("a").oembed) {
    // if the oembed lib has been imported
    $("a.oembed").oembed(null, { embedMethod: "fill" });
  }

  $(document).on("click", "#load_more_link", function (event) {
    $("#load_more_link").addClass("disabled");
    $("#load_more_link").attr("disabled", true);
  });
  $(".fighter-carousel").on("click", function (e) {
    //forces one fight to always be open
    if ($("#" + $(this).attr("alt")).hasClass("show")) {
      e.stopPropagation();
    }
    $(".show")
      .not("#" + $(this).attr("alt"))
      .collapse("hide");
    $("#" + $(this).attr("alt")).collapse("show");
  });

  $(".language-menu li a").click(function () {
    $(".language-toggle").html($(this).html());
  });
	
	
	$('.event-btn-green').click(function(){
		var fightertitle = $(this).attr('id');
		gtag('event', 'Buy Now', {
		  'action': 'click',
		  'screen_page': fightertitle
		});
	});	
	$('.action-btn-green').click(function(){
		gtag('event', 'Buy Now', {
		  'action': 'click',
		  'screen_page': 'Home Slider'
		});
	});	

  $(".btn-stats").click(function () {
    var fight = $(this).attr("id");
    var text = $(this).text();
    if (text.indexOf("SHOW") !== -1) {
      $(this).text("HIDE STATS");
    } else {
      $(this).text("SHOW STATS");
    }
    $("." + fight).toggleClass("show");
  });

  var menu_locale = $(".language-toggle").data("locale");
  $(".language-toggle").html($("#" + menu_locale).html());

  $(".event-link-click").click(function () {
    var url = $(this).attr("id");
    window.location.href = url;
  });

  function goToURL(url) {
    window.location.href = url;
  }

  //TRANSLATIONS
  Glory.loadTranslations();
  Glory.loadLongTranslations();
});
